<template>
  <div class="pagination-base">
    <div class="pagination flex items-center justify-end">
      <div
        class="bg-white border border-[#f3f5fa] rounded shadow flex items-center h-14"
      >
        <div class="btn_left">
          <button
            @click="goToPage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <svg-icon
              name="ic_page-left"
              original
              width="27"
              height="27"
            ></svg-icon>
          </button>
        </div>

        <div
          v-for="pageNumber in pageNumbers"
          :key="pageNumber"
          class="page text-sm xl:text-base 2xl:text-lg font-medium"
          :class="{
            'bg-[#eff0f2] border border-[#eff0f2] rounded h-12 flex':
              pageNumber === currentPage,
          }"
        >
          <button
            @click="goToPage(pageNumber)"
            :class="{ active: pageNumber === currentPage }"
          >
            {{ pageNumber }}
          </button>
        </div>

        <div class="btn_right">
          <button
            @click="goToPage(currentPage + 1)"
            :disabled="currentPage === totalPages"
          >
            <svg-icon
              name="ic_page-right"
              original
              width="27"
              height="27"
            ></svg-icon>
          </button>
        </div>
      </div>
    </div>

    <!--    <br />
    <div class="pagination">
      <div class="bg-white border border-[#f3f5fa] rounded-lg">
        <button
          @click="goToPage(currentPage - 1)"
          :disabled="currentPage === 1"
        >
          <vue-svg-icon
            :data="icons.ic_left"
            original
            width="20"
            height="20"
          ></vue-svg-icon>
        </button>

        <button
          v-for="pageNumber in pageNumbers"
          :key="pageNumber"
          @click="goToPage(pageNumber)"
          :class="{ active: pageNumber === currentPage }"
        >
          {{ pageNumber }}
        </button>

        <button
          @click="goToPage(currentPage + 1)"
          :disabled="currentPage === totalPages"
        >
          <vue-svg-icon
            :data="icons.ic_right"
            original
            width="20"
            height="20"
          ></vue-svg-icon>
        </button>
      </div>
    </div>-->
  </div>
</template>

<script>
import left from '@/assets/icons/ic_page-left.svg'
import right from '@/assets/icons/ic_page-right.svg'
export default {
  name: 'pagination-base',
  props: {
    currentPage: Number,
    totalPages: Number,
    visiblePages: Number
  },
  data () {
    return {
      icons: { left, right }
    }
  },
  computed: {
    pageNumbers () {
      const pages = []
      const halfVisiblePages = Math.floor(this.visiblePages / 2)
      const startPage = Math.max(this.currentPage - halfVisiblePages, 1)
      const endPage = Math.min(
        startPage + this.visiblePages - 1,
        this.totalPages
      )

      if (startPage > 1) {
        pages.push(1)
        if (startPage > 2) {
          pages.push('...')
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i)
      }

      if (endPage < this.totalPages) {
        if (endPage < this.totalPages - 1) {
          pages.push('...')
        }
        pages.push(this.totalPages)
      }

      return pages
    }
  },
  methods: {
    goToPage (pageNumber) {
      if (
        pageNumber !== this.currentPage &&
        pageNumber > 0 &&
        pageNumber <= this.totalPages
      ) {
        this.$emit('page-change', pageNumber)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/style/sass/variables";
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

button {
  margin: 0 5px;
  padding: 5px 15px;
  // cursor: pointer;
  // background-color: #fff;
  // font-size: 14px;
  // font-weight: 500;
}

button.active {
  // background-color: #eff0f2;
  // color: #000;
  // border: 1px solid #eff0f2;
  //border-radius: 10px;
}

/* Ajoutez des styles pour les points (...) selon votre préférence */
</style>
