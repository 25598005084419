<template>
  <div class="membership">
    <div v-if="!addPro" class="begin">
      <div class="header flex items-center justify-between">
        <div class="flex-one validate">
          <validate-option
            :is-invalid-number="invalidNumber"
            :is-pro-number="proNumber"
            @updatePosition="updatePosition"
          />
        </div>
        <div class="flex-one current-month ">
          <div class="search-bloc flex items-center justify-between">
            <div class="recherche-name">
              <div class="relative recherche justify-center items-center pl-8 pr-4 py-4 bg-white shadow rounded">
                <div class="icon-search absolute inset-y-0 left-0 flex items-center px-4">
                  <svg-icon
                    name="ic_search"
                    original
                    class="icon w-4 h-4"
                  />
                </div>
                <div class="search-input ml-4">
                  <label>
                    <input
                      v-model="search"
                      type="text"
                      :placeholder="$t('searchName')"
                      class="recherche-input"
                    >
                  </label>
                </div>
              </div>
            </div>
            <div class="select-pays">
              <town-select
                :status-list="statusList"
                @selectedValue="selectedValue"
              />
            </div>
            <!-- <div class="add ml-4">
              <button-base
                :label="$t('addMemberProButton')"
                @click.native="showAddPro"
              />
            </div> -->
          </div>
        </div>
      </div><br>
      <div class="user-list bg-white rounded font-semibold shadow p-6 flex items-center">
        <div class="flex-one item-color w-2/5">{{ $t('completeName') }}</div>
        <div class="flex-one item-color w-1/5">{{ $t('phone') }}</div>
        <div class="flex-one item-color w-1/3">{{ $t('email') }}</div>
        <div class="flex-one item-color w-1/6">{{ $t('sex') }}</div>
        <div class="flex-one item-color w-1/4">{{ $t('town') }}</div>
        <div class="flex-one item-color w-2/5">{{ $t('pointsMenu') }}</div>
        <div class="flex-one item-color w-1/12"></div>
      </div>
      <div v-if="isPosition === 1" class="list-user cursor-pointer">
        <div class="loading" :class="{ 'not_empty': isLoading === false }">
          <loading
            :active="isLoading"
            :can-cancel="true"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
            loader="dots"
            color="#0B5382"
            background-color="transparent"
          />
        </div>
        <div v-if="nothing && !isLoading" class="no-student text-center text-lg py-32">
          {{ $t('noMembershipSSR') }}
        </div>
        <div v-if="!isLoading">
          <div
            v-for="(item, i) in sortedArrayUser"
            :key="i"
            class="waiting"
          >
            <membership-list
              :member-list="item"
              @click.native="showMembershipDetail(i)"
            />
          </div>

          <pagination-base
            :currentPage="currentPage"
            :totalPages="totalPages"
            :visiblePages="visiblePages"
            @page-change="handlePageChange"
          />
        </div>
      </div>
      <div v-if="isPosition === 2" class="list-user cursor-pointer">
        <div class="loading" :class="{ 'not_empty': isLoading === false }">
          <loading
            :active="isLoading"
            :can-cancel="true"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
            loader="dots"
            color="#0B5382"
            background-color="transparent"
          />
        </div>
        <div v-if="nothingRejected && !isLoading" class="no-student text-center text-lg py-32">
          {{ $t('noRejectedAdded') }}
        </div>
        <div v-if="!isLoading">
          <div
            v-for="(item, i) in sortedArrayUserRejected"
            :key="i"
            class="waiting"
          >
            <membership-list
              :member-list="item"
              @click.native="showMembershipDetail(i)"
            />
          </div>

          <pagination-base
            :currentPage="currentPageRejected"
            :totalPages="totalPagesRejected"
            :visiblePages="visiblePagesRejected"
            @page-change="handlePageChangeRejected"
          />
        </div>
      </div>
      <div v-if="isPosition === 3" class="list-user cursor-pointer">
        <div class="loading" :class="{ 'not_empty': isLoading === false }">
          <loading
            :active="isLoading"
            :can-cancel="true"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
            loader="dots"
            color="#0B5382"
            background-color="transparent"
          />
        </div>
        <div v-if="nothingPro && !isLoading" class="no-student text-center text-lg py-32">
          {{ $t('noProfessionalAdded') }}
        </div>
        <div v-if="!isLoading">
          <div
            v-for="(item, i) in sortedArrayPro"
            :key="i"
            class="waiting"
          >
            <pro-list
              :pro-list="item"
              @click.native="showMembershipDetail(i)"
            />
          </div>

          <pagination-base
            :currentPage="currentPagePro"
            :totalPages="totalPagesPro"
            :visiblePages="visiblePagesPro"
            @page-change="handlePageChangePro"
          />
        </div>
      </div><br>
    </div>
    <membership-detail
      v-if="membershipDetail"
      :member-detail-infos="memberDetail"
      @closeMembershipDetail="closeMembershipDetail"
    />
    <add-pro
      v-if="addPro"
      @clickToGoBack="clickToGoBack"
    />
  </div>
</template>

<script>
import ValidateOption from '../../components/helper/membership/validate-option'
import TownSelect from '../../components/helper/town-select'
import MembershipList from '../../components/helper/membership/membership-list'
import MembershipDetail from '../../components/helper/membership/membership-detail'
import http from '../../plugins/http'
import apiRoute from '../../router/api-routes'
import AddPro from '../../components/helper/membership/add-pro'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import ProList from '../../components/helper/membership/pro-list'
import towns from '@/components/helper/add/allTowns'
import PaginationBase from '@/components/helper/add/pagination-base.vue'
export default {
  name: 'index',
  components: { PaginationBase, ProList, Loading, AddPro, MembershipDetail, MembershipList, TownSelect, ValidateOption },
  data () {
    return {
      isPosition: 1,
      isLoading: true,
      isLoadingRejected: true,
      isLoadingPro: true,
      fullPage: false,
      addPro: false,
      membershipDetail: false,
      search: '',
      statusList: [...towns],
      trieOption: 'all',
      memberships: [],
      membershipsRejected: [],
      memberDetail: {},
      invalidNumber: 0,
      proNumber: 0,
      user: [],
      nothing: false,
      nothingPro: false,
      nothingRejected: false,
      currentPage: 1,
      totalPages: 0,
      visiblePages: 0,
      limit: 10,
      currentPagePro: 1,
      totalPagesPro: 0,
      visiblePagesPro: 0,
      limitPro: 10,
      currentPageRejected: 1,
      totalPagesRejected: 0,
      visiblePagesRejected: 0,
      limitRejected: 10
    }
  },
  created () {
    this.getAllDemand(this.currentPage, this.limit)
    this.getAllDemandRejected(this.currentPageRejected, this.limitRejected)
    // this.getListTown()
    this.getAllUser(this.currentPagePro, this.limitPro)
  },
  mounted () {
    this.statusList.sort(function (x, y) {
      const a = x.name.toUpperCase()
      const b = y.name.toUpperCase()
      return a === b ? 0 : a > b ? 1 : -1
    })
    const all = { name: 'TOUT' }
    this.statusList.unshift(all)
    // console.log(this.statusList)
  },
  computed: {
    sortedArrayUser: function () {
      let sorted = []
      for (let i = 0; i < this.memberships.length; i++) {
        if (this.trieOption === 'all' || this.trieOption === 'TOUT') {
          sorted.push(this.memberships[i])
        } else {
          if (this.memberships[i].user.town.toLowerCase() === this.trieOption.toLowerCase()) {
            sorted.push(this.memberships[i])
          } else if (this.memberships[i].user.location.toLowerCase() === this.trieOption.toLowerCase()) {
            sorted.push(this.memberships[i])
          }
        }
      }
      if (this.search !== '') {
        let authorNameSearchString = this.search
        authorNameSearchString = authorNameSearchString.toLowerCase()
        sorted = sorted.filter(function (item) {
          if (item !== null && typeof item.user.firstName !== 'undefined' && item.user.firstName !== null) {
            if ((item.user.firstName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() + ' ' + item.user.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 || (item.user.phone.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ||
              (item.user.email !== null && (item.user.email.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1)) {
              // console.log(item)
              return item
            }
          }
        })
      }
      return sorted
    },
    sortedArrayUserRejected: function () {
      let sorted = []
      for (let i = 0; i < this.membershipsRejected.length; i++) {
        if (this.trieOption === 'all' || this.trieOption === 'TOUT') {
          sorted.push(this.membershipsRejected[i])
        } else {
          if (this.membershipsRejected[i].user.town.toLowerCase() === this.trieOption.toLowerCase()) {
            sorted.push(this.membershipsRejected[i])
          } else if (this.membershipsRejected[i].user.location.toLowerCase() === this.trieOption.toLowerCase()) {
            sorted.push(this.membershipsRejected[i])
          }
        }
      }
      if (this.search !== '') {
        let authorNameSearchString = this.search
        authorNameSearchString = authorNameSearchString.toLowerCase()
        sorted = sorted.filter(function (item) {
          if (item !== null && typeof item.user.firstName !== 'undefined' && item.user.firstName !== null) {
            if ((item.user.firstName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() + ' ' + item.user.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 || (item.user.phone.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ||
              (item.user.email !== null && (item.user.email.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1)) {
              // console.log(item)
              return item
            }
          }
        })
      }
      return sorted
    },
    sortedArrayPro: function () {
      let sorted = []
      for (let i = 0; i < this.user.length; i++) {
        if (this.trieOption === 'all' || this.trieOption === 'TOUT') {
          sorted.push(this.user[i])
        } else {
          if (this.user[i].user.town.toLowerCase() === this.trieOption.toLowerCase()) {
            sorted.push(this.user[i])
          } else if (this.user[i].user.location.toLowerCase() === this.trieOption.toLowerCase()) {
            sorted.push(this.user[i])
          }
        }
      }
      if (this.search !== '') {
        let authorNameSearchString = this.search
        authorNameSearchString = authorNameSearchString.toLowerCase()
        sorted = sorted.filter(function (item) {
          if (item !== null && typeof item.user.firstName !== 'undefined' && item.user.firstName !== null) {
            if ((item.user.firstName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() + ' ' + item.user.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 || (item.user.phone.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ||
              (item.user.email !== null && (item.user.email.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1)) {
              // console.log(item)
              return item
            }
          }
        })
      }
      return sorted
    }
  },
  methods: {
    handlePageChange (newPage) {
      // Mettez à jour la page actuelle ici (ex: effectuez une requête pour charger la nouvelle page de données)
      this.currentPage = newPage
      this.getAllDemand(newPage, this.limit)
    },
    handlePageChangePro (newPage) {
      // Mettez à jour la page actuelle ici (ex: effectuez une requête pour charger la nouvelle page de données)
      this.currentPagePro = newPage
      console.log(newPage)
      this.getAllUser(newPage, this.limitPro)
    },
    handlePageChangeRejected (newPage) {
      // Mettez à jour la page actuelle ici (ex: effectuez une requête pour charger la nouvelle page de données)
      this.currentPageRejected = newPage
      this.getAllDemandRejected(newPage, this.limitRejected)
    },
    getAllUser (page, limit) { // GET ALL DOCTOR LIST
      http.post(apiRoute.baseURL + apiRoute.getAllUser, {
        type: 'DOCTOR',
        page: page,
        limit: limit
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      })
        .then(response => {
          // console.log('pro', response)
          this.user = response.rows
          this.user.sort(function (x, y) {
            const a = new Date(x.ssr.createdAt)
            const b = new Date(y.ssr.createdAt)
            return a - b
          })
          this.currentPagePro = page
          this.totalPagesPro = Math.ceil(response.count / limit)
          // console.log(this.totalPages)
          const visiblePage = 1
          this.visiblePagesPro = Math.min(visiblePage, this.totalPagesPro)
          if (this.user.length === 0) {
            this.isLoading = false
            this.nothingPro = true
          } else {
            this.isLoading = false
            this.nothingPro = false
          }
          this.proNumber = response.count
        })
        .catch(error => {
          console.log(error)
        })
    },
    getListTown () { // GET BENIN TOWN LIST
      http.get('https://bj-decoupage-territorial.herokuapp.com/api/v1/towns')
        .then(response => {
          // console.log(response.towns)
          this.statusList = response.towns
        }).catch(error => {
          console.log(error)
        })
    },
    clickToGoBack (val) {
      this.addPro = val
    },
    showAddPro () { // SHOW ADD PRO FORMULARY
      this.addPro = true
    },
    getAllDemand (page, limit) { // GET ALL DEMAND REQUEST
      http.post(apiRoute.baseURL + apiRoute.getAllDemand, {
        page: page,
        limit: limit,
        type: 'WAITING'
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      })
        .then(response => {
          console.log(response)
          this.memberships = response.rows
          /* for (const i in response.rows) {
            if (response.rows[i].user.isInvalid === false) {
              this.memberships.push(response.rows[i])
            }
          } */
          this.memberships.sort(function (x, y) {
            const a = new Date(x.ssr.createdAt)
            const b = new Date(y.ssr.createdAt)
            return a - b
          })
          this.currentPage = page
          this.totalPages = Math.ceil(response.count / limit)
          // console.log(this.totalPages);
          const visiblePage = 1
          this.visiblePages = Math.min(visiblePage, this.totalPages)
          if (this.memberships.length === 0) {
            this.isLoading = false
            this.nothing = true
          } else {
            this.isLoading = false
            this.nothing = false
          }
        }).catch(error => {
          console.log(error)
        })
    },
    getAllDemandRejected (page, limit) { // GET ALL DEMAND REQUEST THAT IS REJECTED
      http.post(apiRoute.baseURL + apiRoute.getAllDemand, {
        page: page,
        limit: limit,
        type: 'REJECTED'
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      })
        .then(response => {
          console.log(response)
          this.membershipsRejected = response.rows
          /* for (const i in response.rows) {
            if (response.rows[i].user.isInvalid === true) {
              this.membershipsRejected.push(response.rows[i])
            }
          } */
          this.membershipsRejected.sort(function (x, y) {
            const a = new Date(x.ssr.createdAt)
            const b = new Date(y.ssr.createdAt)
            return a - b
          })
          this.currentPageRejected = page
          this.totalPagesRejected = Math.ceil(response.count / limit)
          // console.log(this.totalPages);
          const visiblePage = 1
          this.visiblePagesRejected = Math.min(visiblePage, this.totalPagesRejected)
          if (this.membershipsRejected.length === 0) {
            this.isLoading = false
            this.nothingRejected = true
          } else {
            this.isLoading = false
            this.nothingRejected = false
          }

          this.invalidNumber = response.count
        }).catch(error => {
          console.log(error)
        })
    },
    closeMembershipDetail () {
      this.membershipDetail = false
    },
    showMembershipDetail (index) {
      if (this.isPosition === 1 || this.isPosition === 2) {
        this.memberDetail = this.memberships[index]
        this.membershipDetail = true
      } else if (this.isPosition === 3) {
        this.memberDetail = this.user[index]
        this.membershipDetail = true
      }
    },
    selectedValue (answer) {
      this.trieOption = answer
    },
    updatePosition (val) {
      this.isPosition = val
      switch (val) {
      case 1:
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        }, 1000)
        break
      case 2:
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        }, 1000)
        break
      case 3:
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        }, 1000)
        break
      }
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .membership {
    padding: 2rem 4rem 4rem 1.5rem;
  }
  .user-list {
    color: $light_gray;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
</style>
