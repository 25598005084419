<template>
  <div class="add-pro">
    <div class="begin">
      <div class="back cursor-pointer">
        <svg-icon
          name="ic_go-back"
          original
          class="w-8"
          @click="goBack"
        />
      </div><br>
      <div class="form bg-white rounded-lg p-8 m-auto w-1/2">
        <div class="title text-black text-xl font-medium">
          {{ $t('addPro') }}
        </div><br>
        <div class="login-form">
          <div class="label mb-2">{{ $t('name') }}</div>
          <div class="input">
            <label>
              <input
                v-model="name"
                type="text"
                class="ipt border rounded-lg w-full p-4"
              >
            </label>
          </div>
        </div><br>
        <div class="login-form">
          <div class="label mb-2">{{ $t('surname') }}</div>
          <div class="input">
            <label>
              <input
                v-model="surname"
                type="text"
                class="ipt border rounded-lg w-full p-4"
              >
            </label>
          </div>
        </div><br>
        <div class="login-form">
          <div class="label mb-2">{{ $t('email') }}</div>
          <div class="input">
            <label>
              <input
                v-model="email"
                type="email"
                class="ipt border rounded-lg w-full p-4"
              >
            </label>
          </div>
        </div><br>
        <div class="login-form">
          <div class="label mb-2">{{ $t('phone') }}</div>
          <div class="input">
            <label>
              <input
                v-model="phone"
                type="tel"
                class="ipt border rounded-lg w-full p-4"
                @keypress="isNumberOnly"
              >
            </label>
          </div>
        </div><br>
        <div class="login-form">
          <div class="label mb-2">{{ $t('sex') }}</div>
          <div class="input w-full">
            <div class="relative">
              <label>
                <select
                  v-model="sex"
                  class="ipt appearance-none w-full border py-4 pl-4 pr-8 rounded-lg"
                >
                  <option value="M">Masculin</option>
                  <option value="F">Féminin</option>
                </select>
              </label>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4">
                <svg-icon
                  name="ic_dropdown"
                  original
                  class="w-4 h-4"
                />
              </div>
            </div>
          </div>
        </div><br>
        <div class="login-form">
          <div class="label mb-2">{{ $t('hospitalName') }}</div>
          <div class="input">
            <label>
              <input
                v-model="hospital"
                type="text"
                class="ipt border rounded-lg w-full p-4"
              >
            </label>
          </div>
        </div><br>
        <div class="login-form">
          <div class="label mb-2">{{ $t('yourProfession') }}</div>
          <div class="input">
            <label>
              <input
                v-model="profession"
                type="text"
                class="ipt border rounded-lg w-full p-4"
              >
            </label>
          </div>
        </div><br>
        <div class="login-form">
          <div class="label mb-2">{{ $t('offeredServicesPro') }}</div>
          <div class="input w-full">
            <div class="relative">
              <label>
                <select
                  v-model="service"
                  class="ipt appearance-none w-full border py-4 pl-4 pr-8 rounded-lg"
                >
                  <option value="M">Masculin</option>
                  <option value="F">Féminin</option>
                </select>
              </label>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4">
                <svg-icon
                  name="ic_dropdown"
                  original
                  class="w-4 h-4"
                />
              </div>
            </div>
          </div>
        </div><br>
        <div class="login-form">
          <div class="label mb-3">{{ $t('cardPicture') }}</div>
          <div class="input">
            <div
              v-if="!fileName"
              class="ipt py-8 w-full border rounded-lg cursor-pointer"
              @click="choosePicture"
            >
              <div class="icones mb-3">
                <svg-icon
                  name="ic_add-image"
                  original
                  class="w-8 h-8 m-auto"
                />
              </div>
              <div class="add text-center">{{ $t('selectionImage') }}</div>
            </div>
            <div v-if="fileName" class="show border rounded-lg w-full h-32">
              <img :src="fileName" class="rounded-lg h-32 w-full">
            </div>
          </div>
        </div><br>
        <div class="login-form">
          <div class="label mb-3">{{ $t('professionPicture') }}</div>
          <div class="input">
            <div
              v-if="!fileNameCard"
              class="ipt py-8 w-full border rounded-lg cursor-pointer"
              @click="choosePictureCard"
            >
              <div class="icones mb-3">
                <svg-icon
                  name="ic_add-image"
                  original
                  class="w-8 h-8 m-auto"
                />
              </div>
              <div class="add text-center">{{ $t('selectionImage') }}</div>
            </div>
            <div v-if="fileNameCard" class="show border rounded-lg w-full h-32">
              <img :src="fileNameCard" class="rounded-lg h-32 w-full">
            </div>
          </div>
        </div><br>
        <div
          v-if="error"
          class="text-red-500 text-center mt-2"
        >
          {{ errorAdmin }}
        </div>
      </div><br>
      <div class="login-button m-auto w-1/2 mb-24">
        <button-base
          :label="$t('addButton')"
          :is-loading="loader"
          @click.native="addNewMember"
        />
      </div>
    </div>
    <success-add
      v-if="successAdd"
      :message="$t('newAddMemberSuccess')"
      :button-label="$t('goBackAddMemberButton')"
      @closeSuccess="closeSuccess"
    />
  </div>
</template>

<script>
import SuccessAdd from '../success-add'
import ButtonBase from '../add/button-base'
import http from '../../../plugins/http'
import apiRoute from '../../../router/api-routes'
export default {
  name: 'add-pro',
  components: { SuccessAdd, ButtonBase },
  data () {
    return {
      name: '',
      surname: '',
      phone: '',
      sex: '',
      email: '',
      hospital: '',
      profession: '',
      service: '',
      file: '',
      fileName: '',
      pictureId: null,
      fileCard: '',
      fileNameCard: '',
      pictureIdCard: null,
      error: false,
      errorAdmin: '',
      loader: false,
      successAdd: false
    }
  },
  methods: {
    closeSuccess () {
      this.successAdd = false
      window.location.reload()
    },
    addNewMember () {
      if (this.name === '' || this.surname === '' || this.phone === '' || this.sex === '' || !this.validEmail(this.email) || this.hospital === '' || this.service === '' || this.profession === '' || this.fileName === '' || this.fileNameCard === '') {
        this.error = true
        this.errorAdmin = this.$t('errorAddAdmin')
      } else {
        this.error = false
        this.loader = true
      }
    },
    choosePicture () {
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*')
      input.onchange = e => {
        this.file = e.target.files[0]
        this.url = URL.createObjectURL(this.file)
        const reader = new FileReader()
        reader.readAsDataURL(this.file)
        reader.onload = a => {
          this.fileName = reader.result
          this.createFileId()
        }
      }
      input.click()
    },
    choosePictureCard () {
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*')
      input.onchange = e => {
        this.fileCard = e.target.files[0]
        // this.url = URL.createObjectURL(this.file)
        const reader = new FileReader()
        reader.readAsDataURL(this.file)
        reader.onload = a => {
          this.fileNameCard = reader.result
          this.createFileIdCard()
        }
      }
      input.click()
    },
    createFileIdCard () {
      http.post(apiRoute.baseURL + apiRoute.createFile, {
        file: this.fileNameCard
      }).then(response => {
        console.log(response)
        this.pictureIdCard = response.id
      }).catch(error => {
        console.log(error)
      })
    },
    createFileId () {
      http.post(apiRoute.baseURL + apiRoute.createFile, {
        file: this.fileName
      }).then(response => {
        console.log(response)
        this.pictureId = response.id
      }).catch(error => {
        console.log(error)
      })
    },
    isNumberOnly ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      // alert(keyCode)
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    },
    validEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    goBack () {
      this.$emit('clickToGoBack', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .ipt {
    background-color: $light_gray3;
    color: black;
  }
  .svg-fill {
    fill: transparent;
  }
  .login-button::v-deep {
    .button-base {
      .page-button-real {
        width: 100%;
        height: 3.5rem;
      }
    }
  }
</style>
