<template>
  <div class="validate-option">
    <div class="begin">
      <div class="header flex items-center">
        <div
          class="part-1 text-black font-medium cursor-pointer mr-8"
          :class="{ 'selected_choice': position === 1 }"
          @click="changePosition(1)"
        >
          {{ $t('waitingValidation') }}
        </div>
        <div
          class="part-1 text-black font-medium cursor-pointer mr-8"
          :class="{ 'selected_choice': position === 3 }"
          @click="changePosition(3)"
        >
          {{ $t('validatePro') + ' (' + isProNumber + ')' }}
        </div>
        <div
          class="part-1 text-black font-medium cursor-pointer"
          :class="{ 'selected_choice': position === 2 }"
          @click="changePosition(2)"
        >
          {{ $t('rejected') + ' (' + isInvalidNumber + ')' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'validate-option',
  props: {
    isInvalidNumber: Number,
    isProNumber: Number
  },
  data () {
    return {
      position: 1
    }
  },
  methods: {
    changePosition (index) {
      this.position = index
      this.$emit('updatePosition', this.position)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .selected_choice {
    background: $white;
    padding: 1rem;
    border-bottom: 1px solid #0B5382;
    border-radius: .25rem;
  }
</style>
