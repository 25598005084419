const towns = [
  {
    name: 'BANIKOARA'
  },
  {
    name: 'GOGOUNOU'
  },
  {
    name: 'KANDI'
  },
  {
    name: 'KARIMAMA'
  },
  {
    name: 'MALANVILLE'
  },
  {
    name: 'SEGBANA'
  },
  {
    name: 'BOUKOUMBE'
  },
  {
    name: 'COBLY'
  },
  {
    name: 'KEROU'
  },
  {
    name: 'KOUANDE'
  },
  {
    name: 'MATERI'
  },
  {
    name: 'NATITINGOU'
  },
  {
    name: 'OUASSA-PEHUNCO'
  },
  {
    name: 'TANGUIETA'
  },
  {
    name: 'TOUKOUNTOUNA'
  },
  {
    name: 'ABOMEY-CALAVI'
  },
  {
    name: 'ALLADA'
  },
  {
    name: 'KPOMASSE'
  },
  {
    name: 'OUIDAH'
  },
  {
    name: 'SO-AVA'
  },
  {
    name: 'TOFFO'
  },
  {
    name: 'TORI-BOSSITO'
  },
  {
    name: 'ZE'
  },
  {
    name: 'BEMBEREKE'
  },
  {
    name: 'KALALE'
  },
  {
    name: "N'DALI"
  },
  {
    name: 'NIKKI'
  },
  {
    name: 'PARAKOU'
  },
  {
    name: 'PERERE'
  },
  {
    name: 'SINENDE'
  },
  {
    name: 'TCHAOUROU'
  },
  {
    name: 'BANTE'
  },
  {
    name: 'DASSA-ZOUME'
  },
  {
    name: 'GLAZOUE'
  },
  {
    name: 'OUESSE'
  },
  {
    name: 'SAVALOU'
  },
  {
    name: 'SAVE'
  },
  {
    name: 'APLAHOUE'
  },
  {
    name: 'DJAKOTOMEY'
  },
  {
    name: 'DOGBO'
  },
  {
    name: 'KLOUEKANMEY'
  },
  {
    name: 'LALO'
  },
  {
    name: 'TOVIKLIN'
  },
  {
    name: 'BASSILA'
  },
  {
    name: 'COPARGO'
  },
  {
    name: 'DJOUGOU'
  },
  {
    name: 'OUAKE'
  },
  {
    name: 'COTONOU'
  },
  {
    name: 'ATHIEME'
  },
  {
    name: 'BOPA'
  },
  {
    name: 'COME'
  },
  {
    name: 'GRAND-POPO'
  },
  {
    name: 'HOUEYOGBE'
  },
  {
    name: 'LOKOSSA'
  },
  {
    name: 'ADJARRA'
  },
  {
    name: 'ADJOHOUN'
  },
  {
    name: 'AGUEGUES'
  },
  {
    name: 'AKPRO-MISSERETE'
  },
  {
    name: 'AVRANKOU'
  },
  {
    name: 'BONOU'
  },
  {
    name: 'DANGBO'
  },
  {
    name: 'PORTO-NOVO'
  },
  {
    name: 'SEME-PODJI'
  },
  {
    name: 'ADJA-OUERE'
  },
  {
    name: 'IFANGNI'
  },
  {
    name: 'KETOU'
  },
  {
    name: 'POBE'
  },
  {
    name: 'SAKETE'
  },
  {
    name: 'ABOMEY'
  },
  {
    name: 'AGBANGNIZOUN'
  },
  {
    name: 'BOHICON'
  },
  {
    name: 'COVE'
  },
  {
    name: 'DJIDJA'
  },
  {
    name: 'OUINHI'
  },
  {
    name: 'ZAGNANADO'
  },
  {
    name: 'ZA-KPOTA'
  },
  {
    name: 'ZOGBODOMEY'
  }
]
export default towns
