<template>
  <div class="pro-list">
    <div class="table-header text-black bg-white rounded shadow px-6 py-4 mt-1 flex items-center">
      <div class="flex-one item-color w-2/5 mr-4">
        <div class="flex items-center">
          <div
            :style="bagroundDefine"
            class="float-left w-8 h-8 text-center pt-2 -mt-1 mr-2 rounded-full first-letter-section text-sm font-bold text-white"
          >
            {{ getFirstLetter(name) }}
          </div>
          <div class="name break-all">
            {{ proList.user.firstName + ' ' + proList.user.lastName }}
          </div>
        </div>
      </div>
      <div class="flex-one item-color w-1/5">
        <div v-if="proList.user.phone === null">-</div>
        <div v-else>{{ proList.user.phone }}</div>
      </div>
      <div class="flex-one item-color w-1/3 mr-4">
        <div v-if="proList.user.email === null">-</div>
        <div v-else>{{ truncateString(proList.user.email, 20) }}</div>
      </div>
      <div class="flex-one item-color w-1/6">
        <div v-if="proList.user.sexe === null">-</div>
        <div v-else>{{ proList.user.sexe }}</div>
      </div>
      <div class="flex-one item-color w-1/4 mr-4">
        <div
          class="benin py-2 px-4 text-center rounded-full"
          :style="backgroundDefine"
        >
          <div v-if="proList.user.location === ''">
            <div v-if="proList.user.town === ''">-</div>
            <div v-else>{{ proList.user.town }}</div>
          </div>
          <div v-else>{{ proList.user.location }}</div>
        </div>
      </div>
      <div class="flex-one item-color w-2/5 mr-4">
        <div v-if="proList.ssr === null">-</div>
        <div v-else>{{ proList.ssr.label }}</div>
      </div>
      <div class="flex-one item-color w-1/12 invisible">
        <div class="flex items-center">
          <div class="update mr-4">
            <svg-icon
              name="ic-delete-black"
              original
              class="w-4 h-4"
            />
          </div>
          <div class="delete">
            <svg-icon
              name="ic_delete-red"
              original
              class="w-4 h-4"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pro-list',
  props: {
    proList: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      name: this.proList.user.firstName + ' ' + this.proList.user.lastName,
      bagroundDefine: {
        backgroundColor: '#000000'
      },
      backgroundDefine: {
        backgroundColor: '#000000'
      }
    }
  },
  created () {
    const colors = ['#01C8C8', '#FFA83D', '#6D1FEB', '#2F80ED', '#0DCE89', '#7D47B3', '#1ECC78', '#C1CD18', '#581845', '#000080']
    const value = Math.floor(Math.random() * (colors.length + 1))
    if (value < colors.length) {
      this.bagroundDefine.backgroundColor = colors[value]
    } else {
      this.bagroundDefine.backgroundColor = colors[0]
    }
    this.townBackground()
  },
  methods: {
    townBackground () {
      const colors = ['rgba(1,101,200,0.1)', 'rgba(0,169,88,0.1)', 'rgba(255,168,61,0.1)', 'rgba(0,169,88,0.1)', 'rgba(1,101,200,0.1)']
      const value = Math.floor(Math.random() * (colors.length + 1))
      if (value < colors.length) {
        this.backgroundDefine.backgroundColor = colors[value]
      } else {
        this.backgroundDefine.backgroundColor = colors[0]
      }
    },
    getFirstLetter (fullname) {
      const nameTable = fullname.split(' ')
      if (nameTable.length >= 2) {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[1].charAt(0).toUpperCase()
      } else {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[0].charAt(1).toUpperCase()
      }
    },
    truncateString (str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .table-header:hover {
    background: rgba(1,101,200,0.1);
    border-left: 2px solid $pass_marron;
    box-shadow: -6px 0px 6px 0px rgba(51, 50, 50, 0.45);
  }
</style>
