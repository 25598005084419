<template>
  <div class="town-select w-full relative">
    <div
      class="flex w-full relative"
      @click="wantToSelect =! wantToSelect"
    >
      <div class="relative justify-center items-center pl-4 pr-8 py-4 cursor-pointer mx-2 bg-white shadow rounded">
        <div class="current-month-text mr-4">
          {{ status }}
        </div>
        <div class="town-select pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg-icon
            name="ic_dropdown"
            original
            class="w-4 h-4"
          />
        </div>
      </div>
    </div>
    <div
      v-if="wantToSelect"
      class="select-list absolute shadow rounded bg-white"
    >
      <div
        v-for="(item, key) in statusList"
        :key="key"
        class="listes flex p-4 cursor-pointer border-b"
        @click="itemIsSelected(item)"
      >
        <h3 class="text-base text-left font-medium">
          {{ item.name }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'town-select',
  props: {
    statusList: {
      type: Array,
      default: function () {
        return [
          {
            name: 'label',
            value: 'value'
          },
          {
            label: 'label',
            value: 'value'
          }
        ]
      }
    }
  },
  data () {
    return {
      status: this.$t('filterCommunity'),
      wantToSelect: false
    }
  },
  methods: {
    itemIsSelected (item) {
      this.wantToSelect = !this.wantToSelect
      this.status = item.name
      this.$emit('selectedValue', item.name)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .select-list {
    width: 14rem !important;
    right: 0;
    max-height: 17rem !important;
    overflow-y: scroll;
  }
  .icon-section {
    margin-top: .2rem;
  }
  .svg-fill {
    fill: transparent;
  }
  .listes:hover {
    background: rgba(1,101,200,0.1);
    // box-shadow: -6px 0px 6px 0px rgba(51, 50, 50, 0.45);
  }
</style>
